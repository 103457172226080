function stylePost() {
  document.querySelectorAll('.post-content p').forEach(function(element) {
    element.classList.add('text-coal', 'text-lg', 'leading-loose', 'my-12')
  })

  document.querySelectorAll('.post-content h2').forEach(function(element) {
    element.classList.add('text-coal', 'text-4xl', 'font-bold', 'mt-12', 'mb-2')
  })

  document.querySelectorAll('.post-content h3').forEach(function(element) {
    element.classList.add('text-coal', 'text-3xl', 'font-bold', 'mt-8', 'mb-2')
  })

  document.querySelectorAll('.post-content h4').forEach(function(element) {
    element.classList.add('text-coal', 'text-2xl', 'font-bold', 'mt-8', 'mb-2')
  })

  document.querySelectorAll('.post-content img').forEach(function(element) {
    element.classList.add('border', 'border-gray-100', 'bg-gray-100')
  })

  document.querySelectorAll('.post-content blockquote').forEach(function(element) {
    element.classList.add('border-l-4', 'border-pink', 'px-8', 'py-1', 'italic')
  })

  document.querySelectorAll('.post-content ul').forEach(function(element) {
    element.classList.add('list-disc')
  })

  document.querySelectorAll('.post-content ul li').forEach(function(element) {
    element.classList.add('text-coal', 'text-lg', 'leading-loose', 'my-12', 'ml-12')
  })
}

document.addEventListener('DOMContentLoaded', function() {
  stylePost()
})
